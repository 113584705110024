var buttonCookieBar = document.getElementsByClassName('cookiebar__close')[0];
var cookieBar = document.getElementById('cookiebar');

if(getCookie('cookie_law_accepted') === undefined) {
    cookieBar.classList.remove('closed');
}

function getCookie(name) {
    return (document.cookie.match('(^|;) *'+name+'=([^;]*)')||[])[2];
}

function setCookieAccepted() {
    // dataLayer.push({'event': 'cookie_law_accepted'});

    expiry = new Date();
    expiry.setTime(expiry.getTime()+(90 * 1000 * 60 * 60 * 24));
    document.cookie = "cookie_law_accepted=1; expires=" + expiry.toGMTString() + "; path=/";

    cookieBar.classList.add("closed");
}

buttonCookieBar.addEventListener('click', function() {
    setCookieAccepted();
});

// function triggerEvent(el, type) {
//     var e = document.createEvent('HTMLEvents');
//     e.initEvent(type, false, true);
//     el.dispatchEvent(e);
// }